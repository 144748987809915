import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateFormsDirective } from 'src/app/directives/validate-forms.directive';
import { DecryptPipe } from 'src/app/shared/pipes/decrypt.pipe';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BuiltFormDataService } from 'src/app/shared/services/built-form-data.service';
import { CryptoSharkService } from 'src/app/shared/services/crypto-shark.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ValidatorsService } from 'src/app/shared/services/validators.service';

@Component({
  selector: 'cat-modal-medic',
  templateUrl: './modal-medic.component.html',
  styleUrls: ['./modal-medic.component.scss']
})
export class ModalMedicComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>(); 

  @Input() medicsRecord;
  @Input() modalOptions: any = null;
  @ViewChild('inpEmail') private inpEmail: any;

  public isLoading: boolean = false;
  public FormMedic: FormGroup; 
  public phones: any[] = [];
  public emails: any[] = [];
  public typePhone: string = 'CEL';
  public inpPhone: any = null;
  public domainsData: any[] = [];
  public specialtiesData: any[] = [];
  public clinicData: any[] = [];
  public sellerData: any[] = [];
  public coloniesData: any[] = [];
  public domain: any;
  public sendMedic = { email: false, web: false};
  public blockEdition: boolean = false;


  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private alertService: AlertService,
    private decrypt: DecryptPipe,
    public validator: ValidatorsService,
    public validateForm: ValidateFormsDirective,
    private cryptoService: CryptoSharkService,
    private userService: UserService,


  ) { }

  ngOnInit(): void { 
    this.FormMedic = this.fb.group({
      name:[null, Validators.compose([Validators.required])],
      email: [[]],
      phone: [[]],
      address: [null],
      colonie: [null],
      active: [true],
      specialty: [null],
      clinic: [null],
      discountSpecial: [0, Validators.compose([Validators.max(100), Validators.min(0)])],
      discountRoutine: [0, Validators.compose([Validators.max(100), Validators.min(0)])],
      medicPorcent: [0, Validators.compose([Validators.max(100), Validators.min(0)])],
      sendMethod: this.fb.group({
        email: [false, Validators.compose([Validators.required])],
        web: [false, Validators.compose([Validators.required])],
      }),
      montlyReport: [false, Validators.compose([Validators.required])],
      userSeller: [null]
    });

    this.loadDomains();
    this.loadSpecialties();
    this.loadClinic();
    this.loadSellers();
    this.loadColonies();

    if(this.modalOptions.type == 'EDIT' || this.modalOptions.type == 'VIEW'){
      this.detailMedic();
    }

    if(this.modalOptions.type == 'VIEW' || (!this.modalOptions?.permissions?.EDIT && this.modalOptions.type == 'EDIT')){
      this.FormMedic.disable();
      this.blockEdition = true;
    }
  }

  async detailMedic(){
    try {
      this.isLoading = true;
      let data = await this.httpService.get(`api/medic/${this.modalOptions.id}`).toPromise()
      this.FormMedic.patchValue({
        name: this.decrypt.transform(data.name),
        address: data.address,
        discountSpecial: data.discountSpecial,
        discountRoutine: data.discountRoutine,
        medicPorcent: data.medicPorcent,
        specialty: data.specialty,
        clinic: data.clinic,
        active: data.active,
        sendMethod: {
          email: data?.sendMethod?.email || false,
          web: data?.sendMethod?.web || false,
        },
        montlyReport: data?.montlyReport || false,
        colonie: data?.colonie,
        userSeller: data?.userSeller
      });

      this.sendMedic.email = data?.sendMethod?.email || false;
      this.sendMedic.web = data?.sendMethod?.web || false;      

      for ( const p of data?.phones || []){p.phone = this.decrypt.transform(p.phone)};
      for ( const m of data?.emails || []){m.email = this.decrypt.transform(m.email)}; 

      this.phones = data?.phones || [];
      this.emails = data?.emails || [];

    } catch (error) {
      console.log(error);
    }finally{
      this.isLoading = false;
    }
  }

  public addPhones() {
    if (this.phones?.filter(item => { return item.phone == this.inpPhone }).length == 0) {
      this.phones.push({ "phone": this.inpPhone, type: this.typePhone, "primary": false });
      this.inpPhone = null;
      this.typePhone = null;
    } else {
      this.alertService.infoToast("El teléfono ya se encuentra agregado.")
    }
  }

  public changePhoneType(event: any): void {
    this.inpPhone = null;
    if (event != "OFFICE") {
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      this.phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ';', /\d/, /\d?/, /\d?/, /\d?/];
    }
  }
 
  public addEmails(mail: string, domain: any): void {
    mail = mail.replace(/\s/g, "");
    if (this.emails?.filter(item => { return item.email == mail + domain }).length == 0) {
      this.emails.push({ "email": mail + domain, "primary": false });
      this.inpEmail.nativeElement.value = null;
      this.domain = null
    } else {
      this.alertService.infoToast("El correo ya se encuentra agregado.")
    }
  }

  async loadDomains() {
    try {
      this.domainsData = await this.httpService.get(`api/domain/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadSpecialties() {
    try {
      this.specialtiesData = await this.httpService.get(`api/specialties/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadClinic() {
    try {
      this.clinicData = await this.httpService.get(`api/clinic/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadSellers() {
    try {
      this.sellerData = await this.httpService.get(`api/users/type/VENDEDOR`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  private async loadColonies() {
    try {
      this.coloniesData = await this.httpService.get(`api/colonies/select`).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public primaryPhone(event: any, index: number): void {
    this.phones.find(phone => { return phone.primary }) ? (this.phones.find(phone => { return phone.primary }).primary = false) : null;
    this.phones[index].primary = event//.target.checked;
  }

  public primaryMail(event: any, index: number): void {
    // this.emails.find(mail => {return mail.primary})?(this.emails.find(mail => {return mail.primary}).primary = false):null;
    this.emails[index].primary = event//.target.checked;
  }

  public changeSendMethod(method: string, value: boolean): void {
    this.FormMedic.get("sendMethod").get(method).setValue(value);
    this.sendMedic[method] = value;
  }

  public changeMensualReport = (value: boolean): void => {
    if (value) {
      this.FormMedic.patchValue({
        discountSpecial: 0,
        discountRoutine: 0
      });
    }else{
      this.FormMedic.patchValue({
        medicPorcent: 0
      });
    }
  }

  public async submit(){
    this.isLoading = true;
    let phonesEncrypt = [];
    let onePhonePrimary = false;
    for (const p of this.phones || []) {
      if (p.primary) onePhonePrimary = true;
      phonesEncrypt.push({ "phone": this.cryptoService.encryptAes(p.phone, this.userService.login.company.dk), "primary": p.primary, type: p.type });
    };

    if (this.phones.length > 0 && !onePhonePrimary) {
      phonesEncrypt[0].primary = true;
    } 
    
    this.FormMedic.value.phone = phonesEncrypt;

    let emailsEncrypt = [];
    let oneMailPrimary = false;
    for (const e of this.emails) {
      if (e.primary) oneMailPrimary = true;
      emailsEncrypt.push({ "email": this.cryptoService.encryptAes(e.email, this.userService.login.company.dk), "primary": e.primary });
    }

    if (this.emails.length > 0 && !oneMailPrimary) {
      emailsEncrypt[0].primary = true;
    }
    
    this.FormMedic.value.email = emailsEncrypt;

    if(!this.FormMedic.valid){ 
      this.FormMedic.markAllAsTouched();
      this.isLoading = false;
      return ;
    } 
    

    let obj:any = await this.validateForm.clearNullObjects(this.FormMedic.value);

    obj.name = this.cryptoService.encryptAes(this.FormMedic.value.name, this.userService.login.company.dk);

    if (this.modalOptions.type == 'ADD') {
      try {
        
        let data = await this.httpService.post(`api/medic`, obj).toPromise();
        this.alertService.successToast("Médico Registrado Correctamente");
        this.onUpdate.emit();
        this.close();
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }finally{
        this.isLoading = false;
      }
    }
    if (this.modalOptions.type == 'EDIT') {
      try {
        let data = await this.httpService.put(`api/medic/${this.modalOptions.id}`, obj).toPromise();
        this.alertService.successToast("Médico actualizado correctamente");
        this.onUpdate.emit();
        this.close();
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }finally{
        this.isLoading = false;
      }
    }

  }

  close(): void { this.onClose.emit() }

}
