import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


// --------------PARAMETROS ---------------
// Todos los parametros deberan llegar en modalOptions
/// _id = Reside el id del registro a cancelar.
/// title = Establece el titulo de la modal.
/// icon  = Establece el icono a la izquierda del titulo de la modal.
/// label = Contiene la etiqueta y placeholder del campo motivo dentro de un objeto. {label: 'Something', placeholder: 'Example'}

@Component({
  selector: 'modal-global-cancel',
  templateUrl: './modal-global-cancel.component.html',
  styleUrls: ['./modal-global-cancel.component.scss']
})
export class ModalGlobalCancelComponent implements OnInit {
  public isLoading: boolean = false;
  public cancelForm: FormGroup;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAutorize: EventEmitter<any> = new EventEmitter<any>();

  @Input() modalOptions: any = null;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    ) { }

  ngOnInit(): void {

    this.cancelForm = this.formBuilder.group({
      id: [this.modalOptions?._id],
      motive: ['', Validators.compose([Validators.required])],
      authorizer: [null, Validators.compose([Validators.required])]
    })
  }

  public sendData = (idUser) =>{
    this.cancelForm.patchValue({
      id: this.modalOptions._id,
      authorizer: idUser
    });

    this.onAutorize.emit(this.cancelForm.value)
  }

  public close = () => { this.onClose.emit() }

  openModal(modal, size: string = 'sm',) {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

}
