import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mk-status',
  templateUrl: './mk-status.component.html',
  styleUrls: ['./mk-status.component.scss']
})
export class MkStatusComponent implements OnInit {

  @Input() status: string;
  @Input() color: string;

  @Input() isTicketStatus: boolean = false;

  public classes = {
    APPROVED: "success",
    REJECTED: "danger",
    PENDING: "warning",
    CANCELLED: "danger",
    INPROGRESS: "warning",
    DONE: "success",
    COMPLETE: "success",
    MOBILE: "secondary",
    TABLET: "info",
    ACTIVE: "success",
    INACTIVE: "danger",
    VISIBLE: "success",
    NOVISIBLE: "danger",
    DENIED: "danger",
    ACCEPT: "success",
    PREVENTIVE: "warning",

    DISPONIBLE: "success",
    NODISPONIBLE: "danger",
    SI: "success",
    NO: "danger",

    PAGADO: 'success',
    PAID: 'success',
    FACTURADO: 'secondary',
    PENDIENTE: 'warning',

    PERFIL: 'info',
    PAQUETE: 'secondary',

    MASCULINO: "primary",
    FEMENINO: "secondary",

    INVOICE_PENDING: "primary",
    CANC_PENDING: "warning",
    BILLED: "success",

    EXTERNAL: "warning",
    ELECTRONIC: "info",
    
    ACTIVO: "success",
    INACTIVO: "danger",

    "CREATED": "warning",
    "EDITION": "primary",
    "REGISTRATION": "success",
    "DELETED": "danger"
  }

  constructor() { 
  }

  ngOnInit(): void {
  }

}
 