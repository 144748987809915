
export const environment = {
  production: false,
  
  apiUrl: 'https://producback.labssystem.com/',
  socketUrl: 'https://producback.labssystem.com/',

  // apiUrl: 'https://betaback.labssystem.com/',
  // socketUrl: 'https://betaback.labssystem.com/',

  // apiUrl: 'http://localhost:8001/',
  // socketUrl: 'http://localhost:8001/',
  
  key: "4XQOY28ECF0JU75BD91NTPALVKS3IMZH"
};
  
