import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'status'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class statusPipe implements PipeTransform {

    public lenguages = {
        PAGADO: 'Pagado',
        PAID: 'PAGADO',
        FACTURADO: 'Facturado',
        PENDIENTE: 'Pendiente',
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        DONE: "Listo",
        COMPLETE: "Completo",

        CANCELLED: "CANCELADO",
        PENDING: "PENDIENTE",
        APPROVED: "APROBADO",
        PROFILE: 'PERFIL',
        PACKAGE: 'PAQUETE',
        STUDY: 'ESTUDIO',
        URGENCY: 'URGENCIA',
        MALE: "MASCULINO",
        FEMALE: "FEMENINO",
        DAY: "Dia(s)",
        MONTH: "Mes(es)",
        YEAR: "Año(s)",
        MONDAY: "Lunes",
        TUESDAY: "Martes",
        WEDNESDAY: "Miercoles",
        THURSDAY: "Jueves",
        FRIDAY: "Viernes",
        SATURDAY: "Sabado",
        SUNDAY: "Domingo",
        EFECTIVO: "Efectivo",
        TARJETA: "Tarjeta",
        "TARJETA CREDITO": "Tarjeta de credito",
        "TARJETA DEBITO": "Tarjeta de debito",
        TRANSFERENCIA: "Transferencia",
        CHEQUE: "Cheque",
        INVOICE_PENDING: "Factura pendiente",
        CANC_PENDING: "Cancelación pendiente",
        BILLED: "Facturado",
        ELECTRONIC: "Electronica",
        EXTERNAL: "Externa",
        ADVANCE: "Adelantada",
        DISABLED: "Deshabilitado",
        PAYMENT: "Pago",
        CLOSE: "Corte",
        OPEN: "Apertura",
        OPTIONS: "Si / No",
        "NUMERIC": "Numerico",
        "DESCRIPTIVE": "Descriptivo",
        "INTERPRETATIVE": "Interpretativo",
        
        "DAYS": "Dia(s)",
        "MOTHS": "Mes(es)",
        "YEARS":"Año(s)",

        OUTFLOW: "Egreso",
        INCOME: "Ingreso",

        DECREASE: "Decremento",
        INCREASE: "Incremento",

        "MODULE": "MODULOS",
        "CATALOGS": "CATALOGOS",

        "CREATED": "Pendientes",
        "REJECTED": "Rechazados",
        "EDITION": "Edición",
        "REGISTRATION": "Creación",
        "DELETED": "Eliminación"
    }
    constructor() {}

    transform(value: string, locale: string = "es"): string {
        return value? this.lenguages[value.toUpperCase()] || value :"" ;
    }

}

interface ILenguege{
    en?: any,
    es?: any,
}

